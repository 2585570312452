/** @jsxImportSource @emotion/react */
import { Theme } from "@emotion/react";
import { ReactComponent as AddGallery } from "./addGallery.svg";
import { ReactComponent as AlignHorizontal } from "./alignHorizontal.svg";
import { ReactComponent as AlignVertical } from "./alignVertical.svg";
import { ReactComponent as Bag } from "./shopping.svg";
import { ReactComponent as Bell } from "./bell.svg";
import { ReactComponent as Box } from "./box.svg";
import { ReactComponent as Cart } from "./Cart.svg";
import { ReactComponent as Chat } from "./chat.svg";
import { ReactComponent as ChatHeart } from "./chat-heart.svg";
import { ReactComponent as CheckRounded } from "./check_rounded.svg";
import { ReactComponent as Chevron } from "./chevron.svg";
import { ReactComponent as Clock } from "./clock.svg";
import { ReactComponent as CloudDownload } from "./cloud-download.svg";
import { ReactComponent as Confetti } from "./confetti.svg";
import { ReactComponent as Copy } from "./copy.svg";
import { ReactComponent as Crown } from "./crown.svg";
import { ReactComponent as Diamond } from "./diamond.svg";
import { ReactComponent as Discord } from "./discord.svg";
import { ReactComponent as EditItem } from "./edit-item.svg";
import { ReactComponent as Ethereum } from "./ethereum.svg";
import { ReactComponent as Expand } from "./expand.svg";
import { ReactComponent as ExternalLink } from "./external_link.svg";
import { ReactComponent as Eye } from "./eye.svg";
import { ReactComponent as Error } from "./iconError.svg";
import { ReactComponent as EyeOpen } from "./eyeOpen.svg";
import { ReactComponent as EyeClosed } from "./eyeClosed.svg";
import { ReactComponent as Farcaster } from "./farcaster.svg";
import { ReactComponent as Cover } from "./fillCover.svg";
import { ReactComponent as Contain } from "./fillContain.svg";
import { ReactComponent as Filter } from "./filter.svg";
import { ReactComponent as Fire } from "./fire.svg";
import { ReactComponent as Globe } from "./globe.svg";
import { ReactComponent as GlobeAlt } from "./globe-alt.svg";
import { ReactComponent as Grab } from "./Grab.svg";
import { ReactComponent as GraduationCap } from "./graduation-cap.svg";
import { ReactComponent as Hearts } from "./hearts.svg";
import { ReactComponent as HugLogo } from "./HugLogo.svg";
import { ReactComponent as ImageAlt2 } from "./image-alt-2.svg";
import { ReactComponent as ImageAlt3 } from "./image-alt-3.svg";
import { ReactComponent as Instagram } from "./instagram.svg";
import { ReactComponent as Keys } from "./keys.svg";
import { ReactComponent as Link } from "./link.svg";
import { ReactComponent as LogoBase } from "./logoBase.svg";
import { ReactComponent as MapMarker } from "./map_marker.svg";
import { ReactComponent as Megaphone } from "./megaphone.svg";
import { ReactComponent as MinusThick } from "./minus-thick.svg";
import { ReactComponent as NewImage } from "./new-image.svg";
import { ReactComponent as NewItem } from "./new-item.svg";
import { ReactComponent as NewReview } from "./new-review.svg";
import { ReactComponent as Objkt } from "./objkt.svg";
import { ReactComponent as Ok } from "./iconOk.svg";
import { ReactComponent as OpenSea } from "./opensea.svg";
import { ReactComponent as Orb } from "./orb.svg";
import { ReactComponent as Order } from "./order.svg";
import { ReactComponent as Palette } from "./palette.svg";
import { ReactComponent as Pause } from "./pause.svg";
import { ReactComponent as Pencil } from "./pencil.svg";
import { ReactComponent as PencilEdit } from "./pencil-edit.svg";
import { ReactComponent as Play } from "./play.svg";
import { ReactComponent as Plus } from "./plus-thick.svg";
import { ReactComponent as PlusThick } from "./plus.svg";
import { ReactComponent as RaisedHands } from "./raised-hands.svg";
import { ReactComponent as Reddit } from "./reddit.svg";
import { ReactComponent as Search } from "./magnifying-glass.svg";
import { ReactComponent as Share } from "./share.svg";
import { ReactComponent as Star } from "./star.svg";
import { ReactComponent as Stars } from "./stars.svg";
import { ReactComponent as Store } from "./iconStore.svg";
import { ReactComponent as TikTok } from "./tiktok.svg";
import { ReactComponent as TrashCan } from "./trash-can.svg";
import { ReactComponent as Twitter } from "./twitter.svg";
import { ReactComponent as UnstyledCircleArrow } from "./arrow-circle.svg";
import { ReactComponent as UnstyledIconArrow } from "./arrow.svg";
import { ReactComponent as UnstyledOutlineArrow } from "./arrow-outline.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as Users } from "./users.svg";
import { ReactComponent as Video } from "./video.svg";
import { ReactComponent as WarningSign } from "./warning-sign.svg";
import { ReactComponent as WarningSignAlt } from "./iconWarning.svg";
import { ReactComponent as Website } from "./website.svg";
import { ReactComponent as X } from "./x_rounded.svg";
import { ReactComponent as YouTube } from "./youtube.svg";

interface IconProps extends React.HTMLAttributes<SVGElement> {
  accent?: string | undefined;
  primary?: "fg" | "bg" | "white" | "black";
  size?: string | number;
  rotate?: number;
}

const iconStyles =
  ({ primary, accent, size = "1em" }: Omit<IconProps, "rotate">) =>
  (theme: Theme) => ({
    color: primary ? theme.colors[primary] : "currentColor",
    display: "inline-block",
    height: "auto",
    ...(accent && {
      "& .accent": {
        fill: theme.colors[accent],
      },
    }),
    ...(size in theme.fontSizes
      ? {
          width: "1em",
          fontSize: theme.fontSizes[size as keyof Theme["fontSizes"]],
        }
      : {
          width: size ?? "1em",
        }),
  });

const withIconProps = <TProps extends object>(
  Component: React.ComponentType<TProps>,
) => {
  function ColoredComponent({
    primary,
    accent,
    size,
    rotate,
    ...props
  }: TProps & IconProps) {
    return (
      <Component
        css={iconStyles({ primary, accent, size })}
        style={rotate ? { transform: `rotate(${rotate}deg)` } : undefined}
        {...(props as TProps)}
      />
    );
  }

  return ColoredComponent;
};

const IconAddGallery = withIconProps(AddGallery);
const IconAlignHorizontal = withIconProps(AlignHorizontal);
const IconAlignVertical = withIconProps(AlignVertical);
const IconBag = withIconProps(Bag);
const IconBell = withIconProps(Bell);
const IconBox = withIconProps(Box);
const IconCart = withIconProps(Cart);
const IconChat = withIconProps(Chat);
const IconChatHeart = withIconProps(ChatHeart);
const IconCheckRounded = withIconProps(CheckRounded);
const IconChevron = withIconProps(Chevron);
const IconClock = withIconProps(Clock);
const IconCloudDownload = withIconProps(CloudDownload);
const IconConfetti = withIconProps(Confetti);
const IconCopy = withIconProps(Copy);
const IconCrown = withIconProps(Crown);
const IconDiamond = withIconProps(Diamond);
const IconDiscord = withIconProps(Discord);
const IconEditItem = withIconProps(EditItem);
const IconEthereum = withIconProps(Ethereum);
const IconError = withIconProps(Error);
const IconExpand = withIconProps(Expand);
const IconExternalLink = withIconProps(ExternalLink);
const IconEye = withIconProps(Eye);
const IconEyeOpen = withIconProps(EyeOpen);
const IconEyeClosed = withIconProps(EyeClosed);
const IconFarcaster = withIconProps(Farcaster);
const IconFilter = withIconProps(Filter);
const IconCover = withIconProps(Cover);
const IconContain = withIconProps(Contain);
const IconFire = withIconProps(Fire);
const IconGlobe = withIconProps(Globe);
const IconGlobeAlt = withIconProps(GlobeAlt);
const IconGraduationCap = withIconProps(GraduationCap);
const IconGrab = withIconProps(Grab);
const IconHearts = withIconProps(Hearts);
const IconHugLogo = withIconProps(HugLogo);
const IconImageAlt2 = withIconProps(ImageAlt2);
const IconImageAlt3 = withIconProps(ImageAlt3);
const IconInstagram = withIconProps(Instagram);
const IconKeys = withIconProps(Keys);
const IconLink = withIconProps(Link);
const IconLogoBase = withIconProps(LogoBase);
const IconMapMarker = withIconProps(MapMarker);
const IconMegaphone = withIconProps(Megaphone);
const IconMinusThick = withIconProps(MinusThick);
const IconNewImage = withIconProps(NewImage);
const IconNewItem = withIconProps(NewItem);
const IconNewReview = withIconProps(NewReview);
const IconObjkt = withIconProps(Objkt);
const IconOpenSea = withIconProps(OpenSea);
const IconOrder = withIconProps(Order);
const IconOk = withIconProps(Ok);
const IconOrb = withIconProps(Orb);
const IconPalette = withIconProps(Palette);
const IconPause = withIconProps(Pause);
const IconPencil = withIconProps(Pencil);
const IconPencilEdit = withIconProps(PencilEdit);
const IconPlay = withIconProps(Play);
const IconPlus = withIconProps(Plus);
const IconPlusThick = withIconProps(PlusThick);
const IconRaisedHands = withIconProps(RaisedHands);
const IconReddit = withIconProps(Reddit);
const IconSearch = withIconProps(Search);
const IconShare = withIconProps(Share);
const IconStar = withIconProps(Star);
const IconStars = withIconProps(Stars);
const IconStore = withIconProps(Store);
const IconTikTok = withIconProps(TikTok);
const IconTrashCan = withIconProps(TrashCan);
const IconTwitter = withIconProps(Twitter);
const IconUser = withIconProps(User);
const IconUsers = withIconProps(Users);
const IconVideo = withIconProps(Video);
const IconWarningSign = withIconProps(WarningSign);
const IconWarningSignAlt = withIconProps(WarningSignAlt);
const IconWebsite = withIconProps(Website);
const IconX = withIconProps(X);
const IconYouTube = withIconProps(YouTube);

interface IconArrowProps extends React.HTMLAttributes<SVGElement> {
  dir?: "ul" | "u" | "ur" | "r" | "dr" | "d" | "dl" | "l";
  variant?: "default" | "circle" | "outline";
}
const degrees = {
  r: "0deg",
  dr: "45deg",
  d: "90deg",
  dl: "135deg",
  l: "180deg",
  ul: "225deg",
  u: "270deg",
  ur: "315deg",
};

function IconArrow({
  dir = "r",
  variant = "default",
  ...props
}: IconArrowProps) {
  let Icon = UnstyledIconArrow;
  if (variant === "circle") {
    Icon = UnstyledCircleArrow;
  } else if (variant === "outline") {
    Icon = UnstyledOutlineArrow;
  }

  return (
    <Icon style={dir && { transform: `rotate(${degrees[dir]})` }} {...props} />
  );
}

// Font Icons, see this directory's readme.md for more info on usage
export type { IconProps, IconArrowProps };
export {
  IconAddGallery,
  IconAlignHorizontal,
  IconAlignVertical,
  IconArrow,
  IconBag,
  IconBell,
  IconBox,
  IconCart,
  IconChat,
  IconChatHeart,
  IconCheckRounded,
  IconChevron,
  IconClock,
  IconCloudDownload,
  IconConfetti,
  IconCopy,
  IconCrown,
  IconDiamond,
  IconDiscord,
  IconEditItem,
  IconError,
  IconEthereum,
  IconExpand,
  IconExternalLink,
  IconEye,
  IconEyeOpen,
  IconEyeClosed,
  IconFarcaster,
  IconFilter,
  IconCover,
  IconContain,
  IconFire,
  IconGlobe,
  IconGlobeAlt,
  IconGrab,
  IconGraduationCap,
  IconHearts,
  IconHugLogo,
  IconImageAlt2,
  IconImageAlt3,
  IconInstagram,
  IconKeys,
  IconLink,
  IconLogoBase,
  IconMapMarker,
  IconMegaphone,
  IconMinusThick,
  IconNewImage,
  IconNewItem,
  IconNewReview,
  IconObjkt,
  IconOk,
  IconOpenSea,
  IconOrder,
  IconOrb,
  IconPalette,
  IconPause,
  IconPencil,
  IconPencilEdit,
  IconPlay,
  IconPlus,
  IconPlusThick,
  IconRaisedHands,
  IconReddit,
  IconSearch,
  IconShare,
  IconStar,
  IconStars,
  IconStore,
  IconTikTok,
  IconTrashCan,
  IconTwitter,
  IconUser,
  IconUsers,
  IconVideo,
  IconWarningSign,
  IconWarningSignAlt,
  IconWebsite,
  IconX,
  IconYouTube,
};

export { ReactComponent as IconAdd } from "./add.svg";
export { ReactComponent as IconApecoin } from "./apecoin.svg";
export { ReactComponent as IconAsync } from "./async.svg";
export { ReactComponent as IconAvalanche } from "./avalanche.svg";
export { ReactComponent as IconBitcoin } from "./bitcoin.svg";
export { ReactComponent as IconCardano } from "./cardano.svg";
export { ReactComponent as IconCelo } from "./celo.svg";
export { ReactComponent as IconCheck } from "./check.svg";
export { ReactComponent as IconChevronBold } from "./chevron_bold.svg";
export { ReactComponent as IconClockElapsed } from "./clock-elapsed.svg";
export { ReactComponent as IconClose } from "./close.svg";
export { ReactComponent as IconCoinbase } from "./coinbase.svg";
export { ReactComponent as IconEnvelopeSmallClosed } from "./envelope-sm-closed.svg";
export { ReactComponent as IconEnvelopeSmallOpen } from "./envelope-sm-open.svg";
export { ReactComponent as IconEthereumLogo } from "./ethereumLogo.svg";
export { ReactComponent as IconEtherscan } from "./etherscan.svg";
export { ReactComponent as IconFeaturedCreator } from "./featured-creator.svg";
export { ReactComponent as IconFeaturedProject } from "./featured-project.svg";
export { ReactComponent as IconFlag } from "./flag.svg";
export { ReactComponent as IconFlagFill } from "./flag_fill.svg";
export { ReactComponent as IconFlow } from "./flow.svg";
export { ReactComponent as IconFoundation } from "./foundation.svg";
export { ReactComponent as IconImx } from "./imx.svg";
export { ReactComponent as IconInfo } from "./info.svg";
export { ReactComponent as IconKlaytn } from "./klaytn.svg";
export { ReactComponent as IconKusama } from "./kusama.svg";
export { ReactComponent as IconLinkedin } from "./linkedin.svg";
export { ReactComponent as IconMagicEden } from "./magic_eden.svg";
export { ReactComponent as IconMedium } from "./medium.svg";
export { ReactComponent as IconMenu } from "./menu.svg";
export { ReactComponent as IconMenuSecondary } from "./menu_secondary.svg";
export { ReactComponent as IconMetamask } from "./metamask.svg";
export { ReactComponent as IconOfficialBadge } from "./official_badge.svg";
export { ReactComponent as IconOkx } from "./okx.svg";
export { ReactComponent as IconOmnichain } from "./omnichain.svg";
export { ReactComponent as IconPolygon } from "./polygon.svg";
export { ReactComponent as IconReactionChefsKiss } from "./reaction_chefs-kiss.svg";
export { ReactComponent as IconReactionMeh } from "./reaction_meh.svg";
export { ReactComponent as IconReactionMehNoBg } from "./reaction_meh_no-bg.svg";
export { ReactComponent as IconReactionMehWhiteBg } from "./reaction_meh_white-bg.svg";
export { ReactComponent as IconReactionMindBlowing } from "./reaction_mind-blowing.svg";
export { ReactComponent as IconReactionMustHug } from "./reaction_must-hug.svg";
export { ReactComponent as IconReactionMustHug2 } from "./reaction_must-hug-2.svg";
export { ReactComponent as IconReactionMustHugNoBg } from "./reaction_must-hug_no-bg.svg";
export { ReactComponent as IconReactionMustHugWhiteBg } from "./reaction_must-hug_white-bg.svg";
export { ReactComponent as IconReactionNah } from "./reaction_nah.svg";
export { ReactComponent as IconReactionNahNoBg } from "./reaction_nah_no-bg.svg";
export { ReactComponent as IconReactionNahWhiteBg } from "./reaction_nah_white-bg.svg";
export { ReactComponent as IconReactionNeedItNow } from "./reaction_need-it-now.svg";
export { ReactComponent as IconReactionObsessed } from "./reaction_obsessed.svg";
export { ReactComponent as IconReactionOutOfThisWorld } from "./reaction_out-of-this-world.svg";
export { ReactComponent as IconReactionTakeMyMoney } from "./reaction_take-my-money.svg";
export { ReactComponent as IconReactionThoughtProvoking } from "./reaction_thought-provoking.svg";
export { ReactComponent as IconReactionYay } from "./reaction_yay.svg";
export { ReactComponent as IconReactionYayNoBg } from "./reaction_yay_no-bg.svg";
export { ReactComponent as IconReactionYayWhiteBg } from "./reaction_yay_white-bg.svg";
export { ReactComponent as IconSkip } from "./skip.svg";
export { ReactComponent as IconSolana } from "./solana.svg";
export { ReactComponent as IconTelegram } from "./telegram.svg";
export { ReactComponent as IconTezos } from "./tezos.svg";
export { ReactComponent as IconWalletConnect } from "./walletconnect.svg";

export { ReactComponent as SliderWave } from "assets/images/slider-wave.svg";
