import { invert } from "lodash";

/**
 * Readonly map of country names to their ISO codes
 */
const countryToCodeMap: Readonly<Record<string, string>> = {
  Afghanistan: "AF",
  Albania: "AL",
  Algeria: "DZ",
  "American Samoa": "AS",
  Andorra: "AD",
  Angola: "AO",
  Anguilla: "AI",
  Antarctica: "AQ",
  "Antigua and Barbuda": "AG",
  Argentina: "AR",
  Armenia: "AM",
  Aruba: "AW",
  Australia: "AU",
  Austria: "AT",
  Azerbaijan: "AZ",
  Bahamas: "BS", // Updated
  Bahrain: "BH",
  Bangladesh: "BD",
  Barbados: "BB",
  Belarus: "BY",
  Belgium: "BE",
  Belize: "BZ",
  Benin: "BJ",
  Bermuda: "BM",
  Bhutan: "BT",
  Bolivia: "BO", // Updated
  "Bonaire, Sint Eustatius and Saba": "BQ",
  "Bosnia and Herzegovina": "BA",
  Botswana: "BW",
  "Bouvet Island": "BV",
  Brazil: "BR",
  "British Indian Ocean Territory": "IO", // Updated
  "Brunei Darussalam": "BN",
  Bulgaria: "BG",
  "Burkina Faso": "BF",
  Burundi: "BI",
  "Cabo Verde": "CV",
  Cambodia: "KH",
  Cameroon: "CM",
  Canada: "CA",
  "Cayman Islands": "KY", // Updated
  "Central African Republic": "CF", // Updated
  Chad: "TD",
  Chile: "CL",
  China: "CN",
  "Christmas Island": "CX",
  "Cocos (Keeling) Islands": "CC", // Updated
  Colombia: "CO",
  Comoros: "KM", // Updated
  "Democratic Republic of Congo": "CD", // Updated
  Congo: "CG", // Updated
  "Cook Islands": "CK", // Updated
  "Costa Rica": "CR",
  Croatia: "HR",
  Cuba: "CU",
  Curaçao: "CW",
  Cyprus: "CY",
  Czechia: "CZ",
  "Côte d'Ivoire": "CI",
  Denmark: "DK",
  Djibouti: "DJ",
  Dominica: "DM",
  "Dominican Republic": "DO", // Updated
  Ecuador: "EC",
  Egypt: "EG",
  "El Salvador": "SV",
  "Equatorial Guinea": "GQ",
  Eritrea: "ER",
  Estonia: "EE",
  Eswatini: "SZ",
  "Falkland Islands": "FK", // Updated
  "Faroe Islands": "FO", // Updated
  Fiji: "FJ",
  Finland: "FI",
  France: "FR",
  "French Guiana": "GF",
  "French Polynesia": "PF",
  "French Southern Territories": "TF", // Updated
  Gabon: "GA",
  Gambia: "GM", // Updated
  Georgia: "GE",
  Germany: "DE",
  Ghana: "GH",
  Gibraltar: "GI",
  Greece: "GR",
  Greenland: "GL",
  Grenada: "GD",
  Guadeloupe: "GP",
  Guam: "GU",
  Guatemala: "GT",
  Guernsey: "GG",
  Guinea: "GN",
  "Guinea-Bissau": "GW",
  Guyana: "GY",
  Haiti: "HT",
  "Heard Island and McDonald Islands": "HM",
  "Holy See": "VA", // Updated
  Honduras: "HN",
  "Hong Kong": "HK",
  Hungary: "HU",
  Iceland: "IS",
  India: "IN",
  Indonesia: "ID",
  Iran: "IR", // Updated
  Iraq: "IQ",
  Ireland: "IE",
  "Isle of Man": "IM",
  Israel: "IL",
  Italy: "IT",
  Jamaica: "JM",
  Japan: "JP",
  Jersey: "JE",
  Jordan: "JO",
  Kazakhstan: "KZ",
  Kenya: "KE",
  Kiribati: "KI",
  "Democratic People's Republic of Korea": "KP", // Updated
  "South Korea": "KR", // Updated
  Kuwait: "KW",
  Kyrgyzstan: "KG",
  Laos: "LA", // Updated
  Latvia: "LV",
  Lebanon: "LB",
  Lesotho: "LS",
  Liberia: "LR",
  Libya: "LY",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  Macao: "MO",
  Madagascar: "MG",
  Malawi: "MW",
  Malaysia: "MY",
  Maldives: "MV",
  Mali: "ML",
  Malta: "MT",
  "Marshall Islands": "MH", // Updated
  Martinique: "MQ",
  Mauritania: "MR",
  Mauritius: "MU",
  Mayotte: "YT",
  Mexico: "MX",
  Micronesia: "FM", // Updated
  Moldova: "MD", // Updated
  Monaco: "MC",
  Mongolia: "MN",
  Montenegro: "ME",
  Montserrat: "MS",
  Morocco: "MA",
  Mozambique: "MZ",
  Myanmar: "MM",
  Namibia: "NA",
  Nauru: "NR",
  Nepal: "NP",
  Netherlands: "NL", // Updated
  "New Caledonia": "NC",
  "New Zealand": "NZ",
  Nicaragua: "NI",
  Niger: "NE", // Updated
  Nigeria: "NG",
  Niue: "NU",
  "Norfolk Island": "NF",
  "Northern Mariana Islands": "MP", // Updated
  Norway: "NO",
  Oman: "OM",
  Pakistan: "PK",
  Palau: "PW",
  Palestine: "PS", // Updated
  Panama: "PA",
  "Papua New Guinea": "PG",
  Paraguay: "PY",
  Peru: "PE",
  Philippines: "PH", // Updated
  Pitcairn: "PN",
  Poland: "PL",
  Portugal: "PT",
  "Puerto Rico": "PR",
  Qatar: "QA",
  "North Macedonia": "MK", // Updated
  Romania: "RO",
  Russia: "RU", // Updated
  Rwanda: "RW",
  Réunion: "RE",
  "Saint Barthélemy": "BL",
  "Saint Helena, Ascension & Tristan da Cunha": "SH", // Updated
  "Saint Kitts and Nevis": "KN",
  "Saint Lucia": "LC",
  "Saint Martin": "MF", // Updated
  "Saint Pierre & Miquelon": "PM", // Updated
  "Saint Vincent & the Grenadines": "VC", // Updated
  Samoa: "WS",
  "San Marino": "SM",
  "Sao Tome & Principe": "ST", // Updated
  "Saudi Arabia": "SA",
  Senegal: "SN",
  Serbia: "RS",
  Seychelles: "SC",
  "Sierra Leone": "SL",
  Singapore: "SG",
  "Sint Maarten": "SX", // Updated
  Slovakia: "SK",
  Slovenia: "SI",
  "Solomon Islands": "SB",
  Somalia: "SO",
  "South Africa": "ZA",
  "South Georgia & the South Sandwich Islands": "GS", // Updated
  "South Sudan": "SS",
  Spain: "ES",
  "Sri Lanka": "LK",
  Sudan: "SD", // Updated
  Suriname: "SR",
  "Svalbard & Jan Mayen": "SJ", // Updated
  Sweden: "SE",
  Switzerland: "CH",
  Syria: "SY", // Updated
  Taiwan: "TW",
  Tajikistan: "TJ",
  Tanzania: "TZ", // Updated
  Thailand: "TH",
  "Timor-Leste": "TL",
  Togo: "TG",
  Tokelau: "TK",
  Tonga: "TO",
  "Trinidad & Tobago": "TT", // Updated
  Tunisia: "TN",
  Turkey: "TR",
  Turkmenistan: "TM",
  "Turks & Caicos Islands": "TC", // Updated
  Tuvalu: "TV",
  Uganda: "UG",
  Ukraine: "UA",
  "United Arab Emirates": "AE", // Updated
  "United Kingdom": "GB", // Updated
  "United States Minor Outlying Islands": "UM", // Updated
  USA: "US", // Updated
  Uruguay: "UY",
  Uzbekistan: "UZ",
  Vanuatu: "VU",
  Venezuela: "VE", // Updated
  "Viet Nam": "VN",
  "British Virgin Islands": "VG", // Updated
  "U.S. Virgin Islands": "VI", // Updated
  "Wallis & Futuna": "WF", // Updated
  "Western Sahara": "EH",
  Yemen: "YE",
  Zambia: "ZM",
  Zimbabwe: "ZW",
  "Åland Islands": "AX",
};

/**
 * Readonly map of country ISO codes to their official names
 */
const codeToCountryMap: typeof countryToCodeMap = invert(countryToCodeMap);

/**
 * Readonly array of country names
 */
const countries: ReadonlyArray<string> = Object.keys(countryToCodeMap);

/**
 * Readonly array of currently sanctioned countries
 */
const sanctionedCountries: ReadonlyArray<string> = [
  "Cuba",
  "Iran (Islamic Republic of)",
  "Korea (the Democratic People's Republic of)",
  "Russian Federation (the)",
  "Sudan (the)",
  "Syrian Arab Republic",
];

export { countries, countryToCodeMap, codeToCountryMap, sanctionedCountries };
