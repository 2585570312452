import styled from "@emotion/styled";
import { trackEvent } from "contexts/mixpanel";
import { H3, IconExternalLink, Link, Text } from "atoms";
import { Thumbnail } from "features/images";
import type { Artist } from "../types";

type ArtistCollectionsProps = {
  collections: Artist["artistCollections"];
};

function ArtistCollections({ collections, ...props }: ArtistCollectionsProps) {
  return (
    <CollectionsList {...props}>
      {collections.map((collection) => (
        <ArtistCollection key={collection.collectionName} {...collection} />
      ))}
    </CollectionsList>
  );
}

const trackClick = (ButtonContext: string, ButtonDestination: string) => () =>
  trackEvent({
    name: "Button Click",
    ButtonName: "Artist Collection",
    ButtonContext,
    ButtonDestination,
  });

type ArtistCollectionProps = Artist["artistCollections"][number];

function ArtistCollection({
  collectionLinks,
  collectionName,
  collectionDescription = "",
  collectionImage,
}: ArtistCollectionProps) {
  const linkUrl = collectionLinks[0]?.url;

  return (
    <CollectionsListItem>
      <CollectionThumbnail
        src={collectionImage}
        alt={collectionName}
        loading="lazy"
      />
      <Description>
        <H3 font="body" size="sm">
          {collectionName}
        </H3>

        {collectionDescription && (
          <TruncatedText size="xxxs">{collectionDescription}</TruncatedText>
        )}

        <FlexSpacer />

        {!!collectionLinks[0]?.url && (
          <Link
            to={linkUrl}
            target="_blank"
            onClick={trackClick(collectionLinks[0]?.type, linkUrl)}
            size="xxs"
            variant="blank"
            style={{ margin: 0 }}
          >
            View more&nbsp;
            <IconExternalLink size=".8em" />
          </Link>
        )}
      </Description>
    </CollectionsListItem>
  );
}

const CollectionsList = styled.ul({
  display: "grid",
  gap: "1rem",
  listStyleType: "none",
  margin: 0,
  padding: 0,
  gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
  "li::before": {
    content: '"\\200B"',
    position: "absolute",
    height: 0,
  },
});

const CollectionsListItem = styled.li(({ theme }) => ({
  borderRadius: theme.borderRadius.md,
  boxShadow: theme.boxShadow.light,
  display: "grid",
  gap: "1em",
  gridTemplateAreas: `"image desc"`,
  gridTemplateColumns: "40% 1fr",
  overflow: "hidden",
  position: "relative",
  img: {
    width: "100%",
    height: "100%",
    aspectRatio: "1",
    objectFit: "cover",
    borderRadius: theme.borderRadius.xs,
  },
  "@media (min-width: 440px)": {
    alignItems: "start",
    gap: "10px 1em",
    gridTemplateColumns: "100%",
    gridTemplateRows: "auto 1fr",
    gridTemplateAreas: `
      "image"
      "desc"
      `,
  },
}));

const CollectionThumbnail = styled(Thumbnail)({
  gridArea: "image",
});

const Description = styled.div(({ theme }) => ({
  alignSelf: "stretch",
  gridArea: "desc",
  padding: "1rem 10px 1rem 0",
  gap: 10,
  display: "flex",
  flexDirection: "column",
  h3: {
    ...theme.fonts.display,
    margin: 0,
    gridArea: "title",
    lineHeight: 1,
  },
  a: {
    alignSelf: "start",
    display: "inline-block",
    fontWeight: "bold",
    marginTop: 3,
    position: "static",
    "&, &:hover, &:focus-visible, &:active": {
      transform: "none",
    },
    "&::before": {
      inset: 0,
      transform: "none",
      transition: "none",
    },
  },
  "@media (min-width: 440px)": {
    padding: "5px 10px 10px 10px",
  },
}));

const FlexSpacer = styled.span({
  flex: 1,
  margin: "-3px 0",
});

const lh = "1.5em";
const TruncatedText = styled(Text)({
  lineHeight: lh,
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: "3",
  textOverflow: "ellipsis",
  marginBottom: 0,
});

export { ArtistCollections };
