import * as React from "react";
import styled from "@emotion/styled";
import placeholderAvatar from "assets/images/default-avatar.svg";
import featuredProjectIcon from "atoms/icons/featured-project.svg";
import {
  AbsoluteLink,
  Avatar,
  EntityBadge,
  H3,
  ScreenReaderText,
  Stack,
} from "atoms";
import { type Image, Thumbnail } from "features/images";
import { preventInvalidForwarding } from "styles/styleUtils";
import { paths } from "utils/paths";
import { pluralize } from "utils/text";
import type { Artist, ReviewableType } from "../types";

interface Props {
  id: string;
  name?: string;
  type: ReviewableType;
  heroImage: Image;
  cardImage?: Image;
  reviewCount?: number;

  featuredGalleryItems?: Artist["featuredGalleryItems"];
  isSmall?: boolean;
  followButton?: React.ReactNode;
  footer?: React.ReactNode;
}

function ReviewableCard({
  id,
  name,
  type,
  heroImage,
  cardImage,
  reviewCount,
  featuredGalleryItems,
  isSmall = false,
  followButton,
  footer,
  ...props
}: Props) {
  const [isSquarish, setIsSquarish] = React.useState(true);
  const handleImgLoad: React.ReactEventHandler<HTMLImageElement> = (event) => {
    const { naturalWidth, naturalHeight } = event.currentTarget;
    setIsSquarish(Math.abs(naturalWidth / naturalHeight - 1) <= 0.1);
  };

  const isArtist = type === "ARTIST";

  const entityURL = paths.artistProfile(id);
  const firstArtistImage = isArtist
    ? featuredGalleryItems?.[0]?.image
    : undefined;

  return (
    <Card {...props} className="ProjectCard">
      {!isSmall && (
        <CardImageWrapper>
          {!isSquarish && (
            <CardImageBackground
              src={cardImage ?? heroImage}
              alt=""
              aria-hidden="true"
              entityType={type}
            />
          )}
          <CardImage
            onLoad={handleImgLoad}
            src={cardImage ?? firstArtistImage ?? heroImage}
            alt={`Featured NFT from ${name} collection`}
            isSquarish={isSquarish}
            loading="lazy"
          />
        </CardImageWrapper>
      )}

      <AbsoluteLink to={entityURL}>
        <ScreenReaderText>{name}</ScreenReaderText>
      </AbsoluteLink>

      <CardHeader>
        {isArtist && (
          <CardAvatar>
            <Avatar
              src={heroImage?.url150 ?? heroImage?.url ?? placeholderAvatar}
              size={40}
            />
          </CardAvatar>
        )}
        <Stack gap="xxs">
          <CardTitle>
            <Name size="xs" textTransform="none" aria-hidden>
              {name}
            </Name>
          </CardTitle>

          <Interactions>
            {reviewCount !== undefined && (
              <Reviews>{pluralize(reviewCount, "Review")}</Reviews>
            )}
            {isArtist ? <>{followButton}</> : <EntityBadge entityType={type} />}
          </Interactions>
        </Stack>
      </CardHeader>

      {footer && <CardFooter>{footer}</CardFooter>}
    </Card>
  );
}

const Card = styled.article(({ theme }) => ({
  background: theme.colors.bgLight,
  borderRadius: theme.borderRadius.sm,
  boxShadow: theme.boxShadow.light,
  overflow: "hidden",
  position: "relative",
}));

const CardHeader = styled.div({
  display: "flex",
  flex: 1,
  gap: "3.33%",
  alignItems: "center",
  justifyContent: "stretch",
  padding: "4% 5%",
  position: "relative",
  "&>div:last-of-type": {
    flex: 1,
  },
});

const CardFooter = styled.div({
  flex: 1,
  padding: "0 5% 4%",
  position: "relative",
  "&>div:last-of-type": {
    flex: 1,
  },
});

const CardAvatar = styled.div({
  flex: "0 0 40px",
  display: "flex",
  alignItems: "start",
  alignSelf: "start",
});

const CardTitle = styled.header(({ theme }) => ({
  alignItems: "center",
  justifyContent: "space-between",
  flexWrap: "nowrap",
  gap: 5,
  display: "flex",
  flex: 1,
  fontSize: theme.fontSizes.xs,
  paddingTop: ".1em",
  "&:only-child": {
    alignItems: "flex-start",
  },
}));
const Name = styled(H3)({
  alignItems: "center",
  display: "flex",
  lineHeight: "1.1",
  margin: 0,
});

type CardImageWrapperProps = { isFeatured?: boolean };

const CardImageWrapper = styled("figure", {
  shouldForwardProp:
    preventInvalidForwarding<CardImageWrapperProps>("isFeatured"),
})<CardImageWrapperProps>(({ isFeatured = false }) => ({
  alignItems: "center",
  aspectRatio: "1",
  display: "flex",
  flex: "0 0 auto",
  justifyContent: "center",
  margin: 0,
  overflow: "hidden",
  position: "relative",
  "@media (prefers-reduced-motion: no-preference)": {
    transition: "opacity 0.1s ease",
  },
  "&:hover": {
    opacity: 0.7,
  },
  ...(isFeatured
    ? {
        "&::after": {
          content: "''",
          backgroundImage: `url(${featuredProjectIcon})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top right",
          backgroundSize: "contain",
          display: "block",
          height: "72px",
          pointerEvents: "none",
          position: "absolute",
          right: "10px",
          top: "10px",
          width: "130px",
          maxWidth: "33%",
          minWidth: "71px",
          zIndex: 3,
        },
      }
    : {}),
}));

const CardImage = styled(Thumbnail)<{ isSquarish: boolean }>(
  ({ isSquarish }) => ({
    zIndex: "2",
    "&:before, &>img": {
      objectFit: isSquarish ? "cover" : "contain",
    },
  }),
);

const CardImageBackground = styled(Thumbnail)({
  bottom: "-10px",
  left: "-10px",
  opacity: ".33",
  position: "absolute",
  top: "-10px",
  width: "calc(100% + 20px)",
  height: "calc(100% + 20px)",
  right: "-10px",
  zIndex: "1",
  filter: "blur(10px)",
  objectFit: "cover",
});

const Interactions = styled.div({
  flex: 1,
  alignItems: "center",
  display: "flex",
  flexWrap: "wrap",
  // gap: "1px 10px",
  gap: "1px 0",
  justifyContent: "space-between",
});

const Reviews = styled.div(({ theme }) => ({
  color: theme.colors.fg70,
  flex: 1,
  fontSize: 12,
  lineHeight: 1.3,
  marginRight: 10,
  whiteSpace: "nowrap",
}));

export { ReviewableCard };
